import img_one from "./workplace_img_1.jpg";
import img_two from "./workplace_img_2.jpg";
import img_three from "./workplace_img_3.jpg";
import img_four from "./workplace_img_4.jpg";
import img_five from "./workplace_img_5.jpg";

const img_arr = [
  { src: img_one, alt: "workplace_img_1" },
  { src: img_two, alt: "workplace_img_2" },
  { src: img_three, alt: "workplace_img_3" },
  { src: img_four, alt: "workplace_img_4" },
  { src: img_five, alt: "workplace_img_5" },
];

export default img_arr;
