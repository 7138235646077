const spring = {
  id: 0,
  name: "Java/Spring",
  tags: ["Java", "Spring", "Rest"],
  resp: [
    "Develop Backend Applications for internal and external usage.",
    "Maintenance and fix bugs on the existing applications.",
    "Work in cross functional teams to find solution to any problem as required.",
    "Assist in the creation of the system design and functional specifications for any new development projects.",
    "Develop API for external usage with third party partners.",
    "Support system integration with third party partners.",
  ],
  req: [
    "Bachelor Degree in Computer Science or equivalent.",
    "1+ Year experience with Java and Spring framework is a plus.",
    "Good knowledge with Object Oriented Programming.",
    "Good knowledge with SQL, Oracle or other RDMBS and database design.",
    "Good knowledge with API development on both Restful and SOAP.",
  ],
};

const vue = {
  id: 1,
  name: "Vue.Js",
  tags: ["Vue", "HTML", "Frontend"],
  skills: "Vue, CSS, HTML, JavaScript",
  resp: [
    "Developing user-facing applications using Vue.js",
    "Building modular and reusable components and libraries",
    "Optimizing your application for performance",
    "Implementing automated testing integrated into development and maintenance workflows",
    "Staying up-to-date with all recent developments in the JavaScript and Vue.js space",
    "Keeping an eye on security updates and issues found with Vue.js and all project dependencies",
    "Proposing any upgrades and updates necessary for keeping up with modern security and development best practices.",
  ],
  req: [
    "Highly proficient with the JavaScript language and its modern ES6+ syntax and features",
    "Highly proficient with Vue.js framework and its core principles such as components, reactivity, and the virtual DOM",
    "Good understanding of HTML5 and CSS3, including {{ Sass or Less depending on your technology stack }}",
    "Knowledge of functional programming and object-oriented programming paradigms",
  ],
};

const react = {
  id: 2,
  name: "React.Js",
  tags: ["React", "Redux", "CSS"],
  skills: "React, Redux, Flux, CSS, HTML, JavaScript",
  resp: [
    "Developing user-facing applications using React.js",
    "Building modular and reusable components and libraries",
    "Optimizing your application for performance",
    "Implementing automated testing integrated into development and maintenance workflows",
    "Staying up-to-date with all recent developments in the JavaScript and React.js space",
    "Keeping an eye on security updates and issues found with React.js and all project dependencies",
    "Proposing any upgrades and updates necessary for keeping up with modern security and development best practices.",
  ],
  req: [
    "3 Year experience with Web Development",
    "1 Year experience with ReactJs, Angular, or VueJs",
    "1 Year experience with Cloud platform",
    "Experienced working as a team and cross-function team",
    "Experienced and Knowledge of SOLID principle or Clean Code",
    "Basic Knowledge of Solution Architecture",
    "Basic Knowledge on Business Analysis",
    "Basic Knowledge of OWASP",
    "1 Year of experience with Agile Development (Scrum) is a plus",
    "1 Year of experience with DevOps or tools such as Jira, Git Repository and Deployment Pipeline, Jenkins, or Sonarqube is a plus",
    "Experienced or Good Knowledge on Micro Frontends is a plus",
    "Experience working at Bank, Financial Services, or International Software Development Solution",
    "Experience working directly with Business team, Marketing team, or Management team",
    "Good Knowledge of Financial Business needs",
  ],
};

const backend = {
  id: 3,
  name: "Backend (Java, Node)",
  skills: "Java, Node, Api, Rest, Soap",
  tags: ["Java", "Node", "Rest"],

  resp: [
    "Develop API for internal and external usage",
    "Maintenance and fix bugs on the existing API services",
    "Work in cross-functional teams to analyze, design & plan on new API",
    "create technical API documents such as API specifications and workflow diagrams",
    "Assist in the creation of the system design and functional specifications for any new development projects",
    "Support system integration with internal and external team",
  ],
  req: [
    "At least 2 years of working experience with API development and some backend application",
    "Experience with some API Management system such as WSO2 is a plus",
    "Experience with Java, Spring framework, and RDBMS",
    "Experience with NodeJS for Client-side API development is a plus",
    "Experience with JSON concepts and RESTful or SOAP APIs",
    "Experience with API/Backend App Development",
    "Experience with Spring framework and RDBMS",
    "Proficient understanding of code versioning tools",
  ],
};

const frontend = {
  id: 4,
  name: "Frontend (React, Angular, Vue)",
  tags: ["Vue", "React", "Angular"],
  skills: "Vue, React, Angular, .Net Core, HTML, CSS, JavaScript",
  resp: [
    "Bachelor’s degree in Computer Science of Information Technology",
    "Minimum 1 years of experience in Web development",
    "Proficiency in HTML/XHTML/CSS. ",
    "Proficiency in HTML5/CSS3/JavaScript and JSON/Ajax/XML.",
    "Experience with various JS libraries and frameworks such as Vue, Angular, etc. is plus.",
    "Basic level experience of ObjectOriented Programming.",
    "Experience in.Net Core (C#, ASP, MVC, …)",
    "Understanding on TSQL command, Entity Framework, LINQ",
    "Strong problemsolving",
    "Strong working in team and independently",
  ],
  req: [
    "Working closely with other members in company",
    "Coding, Prototype, UI/UX concept",
    "Developing new web app, maintain, troubleshoot, and enhance existing applications",
    "Participating in workflow and process, technical review, meeting, and discussion",
    "Conducting code review, testing, and preparing documents",
  ],
};

const seniorMobile = {
  id: 5,
  name: "Senior Mobile App Developer",
  tags: ["Flutter", "React Native", "Android/Ios"],
  skills: "Flutter, React Native, Ios, Android, Kotlin",
  resp: [
    "Develop client-side or server-side services and modules",
    "Develop and manage a well-functioning application",
    "Write effective and secure APIs",
    "The primary focus will be to implement new user interfaces and features together with automated unit and integration tests.",
    "Contribute to further improve the development process as part of an agile team",
    "Developing new features and user interfaces from wireframe models",
    "Ensuring the best performance and user experience of the application",
    "Fixing bugs and performance problems",
    "Writing clean, readable, and testable code",
    "Cooperating with back-end developers, designers, and the rest of the team to deliver well-architected and high-quality solutions",
  ],
  req: [
    "Bachelor’s degree in software engineering, Computer Science or related field required",
    "Minimum 4+ years of experience in developing apps for Android/ iOS platforms",
    "Experience working with desktop and/or mobile applications",
    "Experience in database management systems such as SQLite, MySQL, SQL Server.",
    "Have knowledge in programming communication between mobile and server via TCP / IP, Firebase, Websocket, WebService and JSON / XML protocols, cloud services (preferably AWS)",
    "Knowledge of UI elements & layout, a basic animation technique",
    "Experience of Tool and Framework: Xcode, iOS SDK, UI Kit, Cocoa",
    "Experience in working with Server Side using RESTful, SOAP",
    "Programming of Objective C/Swift for iOS",
  ],
};

const juniorMobile = {
  id: 6,
  name: "Junior Mobile App Developer",
  tags: ["Flutter", "React Native", "Android/Ios"],
  skills: "Flutter, React Native, Ios, Android, Kotlin, Hybrid, UI/UX, Agile",
  resp: [
    "Develop Mobile App, testing, Maintain, Troubleshoot, and enhance existing applications on one or more mobile platforms.",
    "Work closely with another member of the company",
    "Coding, Prototype, UI/UX concept",
    "Participate in workflow and process, technical review, meeting and discussion",
    "Conduct code review, Testing and Prepare documents",
  ],
  req: [
    "At least 1-year experience in Mobile app development",
    "Experience with Agile Method",
    "Fast Learner in new technology",
    "Strong Problem and Solving Problem",
    "Strong Team Work and Work Independently",
    "Highly Responsible for Work",
    "Good Attitude and Communication Skills",
  ],
};

const uiux = {
  id: 7,
  name: "UI/UX Designer",
  tags: ["Figma", "Adobe"],
  resp: [
    "Proven UI experience, graphic design experience, & demonstrable UI design skills with a portfolio",
    "Solid experience in wireframes, storyboards, user flows, process flows and site maps",
    "Proficiency in Photoshop, Illustrator, Sketch, Figma, or other visual design and wire-framing tools",
    "Excellent visual design skills with sensitivity to user-system interaction",
    "Up-to-date with the latest UI trends, techniques, and technologies",
    "Knowledge of best practices for the design of UI and graphic design",
    "Ability to solve problems creatively and effectively",
    "Proficiency in HTML, CSS, and JavaScript for rapid prototyping is an advantage",
    "Ability to present your designs and sell your solutions to various stakeholders is an advantage",
    "Experience working in an Agile/Scrum development process is an advantage",
    "BS/MS in Human-Computer Interaction, Interaction Design, or related is the advantage",
  ],
  req: [
    "Collaborate with product management and engineering team to understand and implement innovative solutions for the product direction, visuals, and experience",
    "Create wireframes, storyboards, user flows, process flows and site maps to effectively communicate interaction and design ideas",
    "Present/defend designs and key milestone deliverables to peers and executive level stakeholders",
    "Establish and promote design guidelines, best practices, and standards",
    "Execute visual design stages from concept to final hand-off to engineering (if possible)",
    "Conceptualize original ideas that bring simplicity & user friendliness to complex design roadblocks (if possible)",
    "Conduct research and evaluate any user feedback (if possible)",
    "Carry out graphic design tasks in the office as needed (Supplementary role subject to the needs and priorities of projects)",
  ],
};

const seniorNetwork = {
  id: 8,
  name: "Senior Network & Security Engineer",
  resp: [
    "Expertise network infrastructure and network engineer",
    "Installation/upgrade of network hardware and software",
    "Troubleshooting the issues and network consulting, maintenance and other",
    "Backup & restoration the configuration if network devices failure",
    "Keep data update, IP & VLANS managing, report incident, and follow up vendors",
    "Managing ASA firewall, Fortigate, L3-Switch, DMVPN, switches, wireless, and MikroTik",
    "Apply ACL, Static Router, NAT (Static/Dynamic), VLAN, Port Security, and QoS of Internet",
    "Maintain Network Security, Update Antivirus (Servers/Clients)",
    "Drawings and required documentation for all engineering changes including incident report",
  ],
  req: [
    "Bachelor’s degree in computer science or equivalent professional qualification",
    "At least 3 years’ experience in network and security or related field",
    "Basic knowledge of shell script in RedHat or Ubuntu operating system",
    "Be able with Cisco router, Firewall, switch, ASA, Fortigate & Securing Network Infrastructure",
    "Strong Documentation, Communication, Report Writing, and Presentation Skills",
    "CCNA or CCNP, MTCNA certification preferred",
    "Creative, dynamic, flexible, self-direct and self-motivated team player",
    "High proficiency spoken and written of English",
  ],
};

const seniorCSharp = {
  id: 9,
  name: "Senior C# Developer",
  skills: ".Net, OOP",
  tags: [".Net", "c#", "MVC"],
  resp: [
    "In charge of developing all BEST software development to ensure that the quality of each release is accurate, efficient and on a timely basis (BEST solutions and partner solutions)",
    "Maintain all development intellectual documents such as TFS, and any related documents.",
    "Liaise with Test Lead to ensure that test cases and procedures are done correctly",
    "Liaise with developers to ensure that defects/bugs are fixed correctly and as on a timely basis",
    "Communicate with solution stakeholders to ensure smooth work (if any)",
    "Ability to negotiate client change requests (if any)",
  ],
  req: [
    "Good in English both spoken and written",
    "Programming skills in Microsoft Technologies (.Net C#)",
    "Strong knowledge and understanding on OOP",
    "Strong problem solving and research skills",
    "Be dynamic, initiative and ability to work under pressure",
    "Knowledge of Accounting/Financial, Billing, Payroll system is a strong advantage",
    "Minimum 3 year-experience in software development",
  ],
};

const seniorBackend = {
  id: 10,
  name: "Senior Back-End Developer",
  skills: "network security, Cloud Architecture, REST, SaaS",
  resp: [
    "Participate in the entire application lifecycle, focusing on coding and debugging",
    "Write clean code to develop functional server applications",
    "Troubleshoot and debug applications",
    "Perform UI tests to optimize performance",
    "Manage cutting-edge technologies to improve legacy applications",
    "Collaborate with front-end developers to integrate user-facing elements with server side",
    "Integrate the front-end elements built by co-workers into the application",
    "Gather and address technical and design requirements",
    "Provide training and support to internal teams",
    "Build reusable code and libraries for future use",
    "Design a problem-solution for later stage implementation",
    "Work with developers, designers, and database administrators to identify new features",
    "Designing, documenting, and developing world-class enterprise applications using the full spectrum of enterprise technologies and design patterns",
    "Defining the scale and scope of projects, including logical and physical landscape requirements, and design, development, and deployment efforts",
    "Collaborating with all stakeholders to translate business requirements into technical requirements",
    "Mentoring engineers in development best practices, design principles and advanced technologies",
  ],
  req: [
    "Bachelor's degree in IT or equivalent fields",
    "5+ years of experience in developing server’s back-end solution",
    "Experience in leading development and/or project teams",
    "Strong understanding of object-oriented analysis and design, network security/performance, SaaS, REST, and Cloud Architecture",
    "Knowledge of design patterns with best practices",
    "Ability to design, develop and implement back-end solution with features including",
    "Ability to think critically and solve problems with well thought-out solutions",
    "Deep understanding of concurrent programming and design.",
    "Deep knowledge and experience of web servers (Nginx, Apache, IIS, …)",
    "Deep knowledge of, and demonstrable experience with C#, Java, Python, PHP,…",
    "Familiarity with .Net Framework, Entity Framework, LINQ, Lambada, SQL Server database]",
  ],
};

const socialMedia = {
  id: 11,
  name: "Social Media Officer",
  skills: "editing, writing, consulting",
  tags: ["Editing", "Facebook", "Social Media"],
  resp: [
    "Build and execute social media strategy through competitive research, platform determination, benchmarking, messaging, and audience identification",
    "Generate, edit, publish and share daily content (original text, images, video) that builds meaningful connections and encourages community members to take action",
    "Set up and optimize company pages within each platform to increase the visibility of the company’s social content",
    "Moderate all user-generated content in line with the moderation policy for each community",
    "Create editorial calendars and syndication schedules",
    "Continuously improve by capturing and analyzing the appropriate social data/metrics, insights, and best practices, and then acting on the information",
    "Collaborate with other departments (customer relations, sales, etc) to manage reputation, identify key players, and coordinate actions",
    "Create ads in social media channels with an effective budget",
    "Provide monthly reports for updates about the progress",
  ],
  req: [
    "Proven working experience in the social media marketing or as a Digital Media Specialist",
    "Excellent consulting, writing, editing (photo/video/text), presentation, and communication skills",
    "Demonstrable social networking experience and social analytics tools knowledge",
    "Knowledge of online marketing and a good understanding of major marketing channels",
    "Positive attitude, detail, and customer-oriented  with good multitasking and organizational ability",
    "Fluency in English",
    "Bachelor degree in Communications, Marketing, Business, New Media or Public Relations",
  ],
};

const templateArr = [
  spring,
  vue,
  react,
  backend,
  seniorBackend,
  frontend,
  seniorMobile,
  juniorMobile,
  uiux,
  seniorNetwork,
  seniorCSharp,
  socialMedia,
];

export default templateArr;
