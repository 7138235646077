import axios from "axios";

const instance = axios.create();

instance.defaults.baseURL = "https://romjobs-server.herokuapp.com/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  instance.defaults.baseURL = "http://localhost:5000/api/";
}

instance.interceptors.request.use(function (req) {
  let user = localStorage.getItem("user");

  if (user) {
    const { token } = JSON.parse(localStorage.getItem("user"));
    req.headers.authorization = `BEARER ${token}`;
    return req;
  }

  if (!user) user = sessionStorage.getItem("user");

  if (user) {
    const { token } = JSON.parse(sessionStorage.getItem("user"));
    req.headers.authorization = `BEARER ${token}`;
    return req;
  }

  return req;
});

export default instance;
